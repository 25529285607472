import { useNavigate } from "react-router-dom";
import "./thankyou.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
const ThankYou = () => {
  const navigate = useNavigate();
  const OrganizationId = Cookies.get("OrganizationId");
  const handleBackToLogin = () => {
    navigate('/');
  };

  return (
    <section>
      <div className="card__container">
        <div className="thankyou_cardwrap d-flex justify-content-center align-items-center">
          <div className="card thankyou__thankyoucard">
            <h1 className="text-center thankyoucard__title">Thank You !</h1>
            <div className="thankyou__okiconwrap text-center">
              <FontAwesomeIcon icon={faCheckCircle} className="circleIcon" />
            </div>
            <div className="card-body text-center thankyou__customcardbody">
              <h6 className="card-title thankyou__cardtitletext">
                Thanks For Your Test Submission
              </h6>
              <p className="card-text thankyou__cardtextpara">
                Your Submission Is Received & We Will Contact You Soon.
              </p>
              <strong className="thankyou__cardtitletext">
                If you have any question or query then feel free to get in
                contact us.
              </strong>
              <p className="thankyou__cardsmalltext">All the best !</p>
              <div className="thankyou__hrmlogowrap m-auto pb-3">
                <img
                  alt=""
                  src="https://www.hrmware.com/images/logo-header.png"
                  className="thankyou__customlogo"
                />
              </div>
              <button
                type="button"
                className="btn btn-primary thankyou__loginredirectbtn"
                id="customButton"
                onClick={handleBackToLogin}
              >            
                <FontAwesomeIcon
                  icon={faLongArrowAltLeft}
                  className="fontIconTy"
                />
                BACK TO LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ThankYou;
