import { useState, useEffect, useRef, useLayoutEffect } from "react";

import "./questionsPaper.css";
import Cookies from "js-cookie";
import { Editor } from "@monaco-editor/react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { candidate_test } from "../helper/axios-instance/CanddiateQuestion";
import isTokenExpired from "../helper/axios-instance/CanddiateQuestion";
import Webcam from "react-webcam";
import VideoRecorder from "../components/VideoRecorder";
import ExamHeader from "../header/ExamHeader";

toast.configure({
  autoClose: 2000,
  pauseOnFocusLoss: false,
});

const mimeType = 'video/webm; codecs="opus,vp8"';

const QuestionsPaper = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("javascript");
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const [cameraPermission, setCameraPermission] = useState(true);
  const [QuestionList, setQuestionList] = useState([]);
  const [timeIsZero, setTimeIsZero] = useState(false);
  const savedQuestionIndex = localStorage.getItem("currentQuestionIndex");

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(
    savedQuestionIndex ? parseInt(savedQuestionIndex, 10) : 0
  );
  const [videoStopped, setVideoStopped] = useState(true);

  const [focustimeIndex, setFocustimeIndex] = useState(0);
  const [testPermissions, setTestPermissions] = useState({
    edit_answer: false,
    continue_test: false,
  });
  // Load selected answers from local storage
  const [selectedAnswer, setSelectedAnswer] = useState(
    JSON.parse(localStorage.getItem("answers")) || []
  );

  const { state } = useLocation();
  const OrganizationId = Cookies.get("OrganizationId");
  const [permission, setPermission] = useState(false)
  const [loading , setLoading] = useState(false)
  const [loadingForNext , setLoadingForLoadingNext] = useState(false)

  const [codeOutput, setCodeOutput] = useState("");
  const executeCode = () => {
    try {
      // Capture the code's output and set it in the codeOutput state variable
      const captureConsoleLog = (log) => {
        setCodeOutput((prevOutput) => prevOutput + log + "\n");
      };

      // Redirect console.log to captureConsoleLog
      const originalConsoleLog = console.log;

      // Execute the code
      const result = eval(code); // Execute the code (This is simplified and not recommended for production)

      // Restore original console.log
    } catch (error) {}
  };

  const appId = Cookies.get("app_id");
  const candidate_uuid = Cookies.get("candidate_uuid");

  const getQuestionList = async () => {
    try {
      const resposne = await candidate_test.get(
        `/question?candidate_uuid=${candidate_uuid}`
      );
      if (resposne.status === 200) {
        localStorage.setItem("QuestionList", JSON.stringify(resposne.data.data.questions));
        setQuestionList(resposne.data.data.questions);

        setTestPermissions(resposne.data.data.permission[0]);
      }
    } catch (error) {
      if (error.response.status === 400 || error.response.status === 500) {
        toast.error(error.response.data.message);
        localStorage.clear();
        Cookies.remove("candidate_token");
        if (error.response.data.message == "Epmty question set for this test") {
          navigate(`/?key=${OrganizationId}`);
        }
      } else if (error.response.status === 401) {
        Cookies.remove("candidate_token");
        localStorage.clear();
        navigate(`/?key=${OrganizationId}`);
      }
    }
  };

  useEffect(() => {
    const checkTokenAndGetData = async () => {
      const isExpired = await isTokenExpired();
      if (!isExpired) {
        // getQuestionList();
      }
    };
    getQuestionList();
    checkTokenAndGetData();
  }, []);
  // Get the current question based on the currentQuestionIndex
  const currentQuestion = QuestionList[currentQuestionIndex];

  const handleNext = async () => {
    setLoadingForLoadingNext(true)
    const isClicked = localStorage.getItem("nextClicked")
    if (isClicked) {
      setVideoStopped(false);
    }
    else{
      setVideoStopped(true);
      localStorage.setItem("nextClicked", "true");
    }
    
    // Increment the current question index to show the next question
    if (currentQuestionIndex < QuestionList.length - 1) {
      try {
        
        capture();
        const answer =
          selectedAnswer[currentQuestionIndex] !== undefined
            ? selectedAnswer[currentQuestionIndex]
            : code[currentQuestionIndex] !== undefined
            ? code[currentQuestionIndex]
            : null;

        // Store the question data in localStorage
        // setCurrentQuestionIndex(currentQuestionIndex + 1);

        setFocustimeIndex(currentQuestionIndex);

        const answersData = QuestionList?.map((question, index) => {
          const questionId = question.question_uuid;
          const answer =
            selectedAnswer[index] !== undefined
              ? selectedAnswer[index]
              : code[index] !== undefined
              ? code[index]
              : null;
          return {
            question_id: questionId,
            answer: answer,
          };
        });
        cameraPermission &&
          localStorage.setItem("answerValue", JSON.stringify(answersData));
      } catch (error) {}
    }
      const formData = await handleFormSubmit();
      const response = await candidate_test.post(
        `/add/all/answer?app_id=${appId}`,
        formData
      );
      if (response.status === 200) {
        localStorage.setItem("currentQuestionIndex", currentQuestionIndex + 1);
        setCurrentQuestionIndex(currentQuestionIndex + 1);
          setLoadingForLoadingNext(false)

        console.log("Submitted");
      }
      else{
        setLoadingForLoadingNext(false)
      }
  };

  const handlePrevious = () => {
    setVideoStopped(false);
    // Decrement the current question index to show the previous question
    if (currentQuestionIndex > 0) {
      localStorage.setItem("currentQuestionIndex", currentQuestionIndex - 1);
      setCurrentQuestionIndex(currentQuestionIndex - 1);

      setFocustimeIndex(currentQuestionIndex);
    }
  };

  useEffect(() => {
    // Retrieve answers from local storage
    const answersFromLocalStorage = JSON.parse(
      localStorage.getItem("answerValue")
    );

    if (answersFromLocalStorage) {
      // Create a copy of the current selectedAnswer state
      const updatedSelectedAnswer = [...selectedAnswer];

      // Iterate over the answers retrieved from local storage and update the selectedAnswer state
      answersFromLocalStorage.forEach((answer) => {
        const { question_id, answer: storedAnswer } = answer;

        // Find the index of the question_id in QuestionList
        const questionIndex = QuestionList.findIndex(
          (question) => question.question_uuid === question_id
        );

        if (questionIndex !== -1) {
          updatedSelectedAnswer[questionIndex] = storedAnswer;
        }
      });

      // Update the selectedAnswer state
      setSelectedAnswer(updatedSelectedAnswer);
    }
  }, [QuestionList]);

  const handleAnswerSelect = (optionIndex) => {
    const newSelectedOptions = [...selectedAnswer];
    newSelectedOptions[currentQuestionIndex] = optionIndex;
    setSelectedAnswer(newSelectedOptions);
  };
  const handleTextAnswerChange = (selectedOption) => {
    const newSelectedOptions = [...selectedAnswer];
    newSelectedOptions[currentQuestionIndex] = selectedOption;
    setSelectedAnswer(newSelectedOptions);
  };
  const [submitted, setSubmitted] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const focusOutTime = {
    candidate_id: appId,
    question_id:
      QuestionList[focustimeIndex ? focustimeIndex : currentQuestionIndex]
        ?.question_uuid,
  };

  const [focusTime, setFocusTime] = useState();
  const sendFocusOutTimeData = async (focus_in_time, focus_out_time) => {
    try {
      const response = await candidate_test.post(`/tab/focus`, {
        ...focusOutTime,
        focus_out_time,
        focus_in_time,
      });
      setFocusTime();
    } catch (error) {}
  };

  useEffect(() => {
    const handleBlur = () => {
      setIsActive(true);

      setFocusTime(new Date());
      if (timeIsZero) {
        sendFocusOutTimeData(focusTime, new Date());
      }
    };

    const handleFocus = () => {
      setIsActive(false);
      if (true) {
        sendFocusOutTimeData(new Date(), focusTime);
      }
    };

    window.addEventListener("blur", handleBlur);
    window.addEventListener("focus", handleFocus);

    return () => {
      window.removeEventListener("blur", handleBlur);
      window.removeEventListener("focus", handleFocus);
    };
  }, [isActive, timeIsZero]);

  const webcamRef = useRef(null);
  const currentQuestionRef = useRef();
  useEffect(() => {
    currentQuestionRef.current = currentQuestion;
  }, [currentQuestion]);

  if (!cameraPermission) {
    toast.warn("Camera is not Working");
    setTimeout(() => {
      navigate(`/?key=${OrganizationId}`);
    }, 4000);
  }

  const capture = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => {
        setCameraPermission(true);
        const imageSrc = webcamRef.current?.getScreenshot();
        const webcamImageObj = {
          candidate_id: appId,
          question_id: currentQuestionRef.current?.question_uuid,
          image: imageSrc,
        };

        const webcamImageData = async () => {
          await candidate_test.post(`/capture/image`, webcamImageObj);
          // toast.info("Image has been Captured Successfully");
        };
        webcamImageData();
      })
      .catch(() => {
        setCameraPermission(false);
      });
  };

  const minTime = 1 * 60000; /* in minutes */
  const maxTime = 2 * 60000; /* in minutes */

  useEffect(() => {
    let interval = setInterval(() => {
      capture();
    }, Math.random() * (maxTime - minTime) + minTime);
    return () => clearInterval(interval);
  }, []);

  const deleteDatabase = (dbName) => {
    const request = indexedDB.deleteDatabase(dbName);
    
    request.onsuccess = () => {
      console.log(`Database ${dbName} deleted successfully`);
    };
  };

  const handleSubmitTest = async () => {
    setLoading(true);
    try {
      if (timeIsZero) {
        const formData = await handleFormSubmit();
        const response = await candidate_test.post(
          `/add/all/answer?app_id=${appId}`,
          formData
        );
        if (response.status === 200) {
          Cookies.remove("candidate_token");
          localStorage.clear();
          deleteDatabase("examDB");
          if (!Cookies.get("candidate_token")) {
            navigate("/thankyou");
          }
        }
      } else {
        const alertmsg = window.confirm(
          "once test is submitted you cannot start the test"
        );
        if (alertmsg) {
          setSubmitted(true);
          const formData = await handleFormSubmit();
          const response = await candidate_test.post(
            `/add/all/answer?app_id=${appId}`,
            formData
          );
          if (response.status === 200) {
            setLoading(false);
            Cookies.remove("candidate_token");
            localStorage.clear();
            deleteDatabase("examDB");
            if (!Cookies.get("candidate_token")) {
              navigate("/thankyou");
            }
          }
        }
      }
    } catch (error) {
      if (error?.response?.status === 400 || error?.response?.status === 500) {
        <p className="validText">{error.response.data.message}</p>;
      }
    }
  };

  useEffect(() => {
    if (timeIsZero === true) {
      handleSubmitTest();
    }
  }, [timeIsZero]); // when `timeIsZero` test is sumbitted
  


  const handleFormSubmit = async () => {
    const formData = new FormData();
    var answersData = await Promise.all(
      QuestionList?.map(async (question, index) => {
      const questionId = question.question_uuid;
      const testId = question.test_id;
      localStorage.setItem("testId", testId);
      let answer;
      if(selectedAnswer[index]?.startsWith("data:")){
        const urlToConvert = selectedAnswer[index];
          const mimeType = 'video/webm';
          const startIndex = urlToConvert.indexOf(';base64,') + 8;
          const base64Data = urlToConvert.substring(startIndex);
          const blob = await (await fetch(`data:${mimeType};base64,${base64Data}`)).blob();
          const videoBlob = new Blob([blob], { type: mimeType });
          const file= new File([videoBlob], 'recording.webm', { type: mimeType });
          answer=file;
          formData.append(questionId, file)
        
        } else if (code[index]) {
          answer = code[index];
        } else {
          answer=selectedAnswer[index];
        }
        return {
          question_id: questionId,
          test_id: testId,
          answer: answer,
        };
      })
    );

    formData.append("data", JSON.stringify(answersData));
    return formData;
  };

  const UpdateLocalStorage = () => {
    const answersData = QuestionList?.map((question, index) => {
      const questionId = question.question_uuid;
      const answer =
        selectedAnswer[index] !== undefined
          ? selectedAnswer[index]
          : code[index] !== undefined
          ? code[index]
          : null;
      return {
        question_id: questionId,
        answer: answer,
      };
    });
    cameraPermission &&
      localStorage.setItem("answerValue", JSON.stringify(answersData));
  };

  const buttonRef = useRef(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const triggerButtonClick = async () => {
    setSubmitted(true);
    const formData = new FormData();
    const answerData = JSON.parse(localStorage.getItem("answerValue"));
    const questionList = JSON.parse(localStorage.getItem("QuestionList"));

    var answersData = await Promise.all(
    questionList?.map(async (question, index) => {
      const questionId = question.question_uuid;
      const testId = question.test_id;
      localStorage.setItem("testId", testId); // Storing testId in localStorage

      let answer;
      // If an answer is selected from the 'selectedAnswer' array
      if (selectedAnswer[index]) {
        const urlToConvert = selectedAnswer[index].answer;
        const mimeType = 'video/webm';
        const startIndex = urlToConvert.indexOf(';base64,') + 8;
        const base64Data = urlToConvert.substring(startIndex);
        const blob = await (await fetch(`data:${mimeType};base64,${base64Data}`)).blob();
        const videoBlob = new Blob([blob], { type: mimeType });
        const file = new File([videoBlob], 'recording.webm', { type: mimeType });
        answer = file;
        formData.append(questionId, file);
      } else if (code[index]) { // If code is provided for the question
        answer = code[index];
      } else { // If no selected answer or code, fall back to selectedAnswer
        answer = selectedAnswer[index];
      }

      return {
        question_id: questionId,
        test_id: testId,
        answer: answer,
      };
    })
  );

  formData.append("data", JSON.stringify(answersData));

  const response = await candidate_test.post(
    `/add/all/answer?app_id=${appId}`,
    formData
  );
  if (response.status === 200) {
    Cookies.remove("candidate_token");
    localStorage.clear();
    deleteDatabase("examDB");
    if (!Cookies.get("candidate_token")) {
      navigate("/thankyou");
    }
  }
};
  
  useEffect(() => {
    const isPageLoadedBefore = localStorage.getItem('pageLoaded');

    const handleBeforeUnload = (event) => {
      const message = "Once the test is submitted, you cannot start the test.";
      event.returnValue = message; 
      return message;
    };

    if (isPageLoadedBefore) {
        triggerButtonClick();
    } else {
      localStorage.setItem('pageLoaded', 'true');
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    // Set the first load state after the page load logic
    setIsFirstLoad(false);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false); 
  const [imageId, setImageId] = useState(); 

  const handleModalOpen = () => {
    setIsModalOpen(true); 
  };

  const handleModalClose = () => {
    setIsModalOpen(false); 
  };

  return (
    <>
      <ExamHeader state={state} setTimeIsZero={setTimeIsZero} />
      <section className="question-main">
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          mirrored
          minScreenshotWidth={200}
          minScreenshotHeight={150}
          onUserMedia={() => setCameraPermission(true)}
          onUserMediaError={() => setCameraPermission(false)}
          className="webcam"
        />
        {cameraPermission && (
          <div className="question">
            <div className="container mb-auto" style={{marginTop: "50px"}}>
              <div className="row">
                <div className="col-12">
                  {currentQuestion && (
                    <form
                      name={`question_${currentQuestionIndex}`}
                      className="questionform"
                    >
                      <figure className="question-card">
                        <div className="question-card-body">
                          <div className="question-card-question">
                            <div className="question-card-number">
                              <p className="questionInfo my-0">
                              Question{" "}
                              <span
                                className="FFC107"
                                style={{ fontWeight: "bolder" }}
                              >
                                {currentQuestionIndex + 1}{" "}
                              </span>{" "}
                              out of{" "}
                              <span
                                className="FFC107"
                                style={{ fontWeight: "bolder" }}
                              >
                                {QuestionList.length}{" "}
                              </span>
                            </p>
                            </div>
                            <div className="custom_paraarea">
                            </div>
                          </div>
                          <div className="question-card-answers">
                            <div className={currentQuestion.question_type == 4 ?
                            "mt-0 mb-0 questions": "mt-0 mb-4 questions"}>
                                {currentQuestion?.question_type == 3 ? <pre className="mb-0 h6" style={{fontWeight:"bold"}}>
                                  {currentQuestion?.question}
                                </pre>  : 
                                <h6 style={{fontWeight:"bold"}}>{currentQuestion?.question_type !== 4
                                  ? currentQuestion?.question
                                  : currentQuestion.question_type == 4 &&
                                    (selectedAnswer[currentQuestionIndex] ||
                                      permission)
                                  ? currentQuestion?.question
                                  : ""}</h6> }                        
                            </div>
                          {currentQuestion.question_image &&
                            <div className="pb-4 pt-3 mb-3">
                              <img src={currentQuestion.question_image} alt="Question image" className="question-image" height={"200px"} width={"100%"} onClick={() => {
                                handleModalOpen();
                                setImageId(currentQuestion.question_image);
                              }} />
                            </div>
                          }

                            {(currentQuestion.question_type === 2 ||
                              currentQuestion.question_type === 5) && (
                              <ul className="question-list list-unstyled my-0">
                                {/* Render multiple-choice options here */}
                                {currentQuestion.options?.map(
                                  (option, optionIndex) => (
                                    <li
                                    className="question-item"
                                    key={optionIndex}
                                  >
                                    <label
                                      htmlFor={`question_${currentQuestionIndex}_${optionIndex}`}
                                      className="custom-checkbox"
                                    >
                                      <input
                                        type="checkbox"
                                        name={`question_${currentQuestionIndex}`}
                                        id={`question_${currentQuestionIndex}_${optionIndex}`}
                                        checked={
                                          selectedAnswer[
                                            currentQuestionIndex
                                          ] === option
                                        }
                                        onChange={() =>
                                          handleAnswerSelect(option)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                      {option} {/* Add option text here */}
                                    </label>
                                  </li>
                                  )
                                )}
                              </ul>
                            )}
                            {currentQuestion.question_type === 1 && (
                              <textarea
                                placeholder="Write your answer here..."
                                className="question-input"
                                value={
                                  selectedAnswer[currentQuestionIndex] || ""
                                }
                                onChange={(e) =>
                                  handleTextAnswerChange(e.target.value)
                                }
                              />
                            )}
                            {currentQuestion.question_type === 3 && (
                              <>
                                <div className="language-dropdown mb-2">
                                  <label
                                    htmlFor="languageSelect"
                                    className="me-2"
                                  >
                                    Select a language :{" "}
                                  </label>
                                  <select
                                    id="languageSelect"
                                    onChange={(e) =>
                                      setSelectedLanguage(e.target.value)
                                    }
                                    value={selectedLanguage}
                                    style={{
                                      padding: "2px",
                                      borderRadius: "4px",
                                    }}
                                  >
                                    <option value="javascript">
                                      JavaScript
                                    </option>
                                    <option value="python">Python</option>
                                    <option value="java">Java</option>
                                    <option value="c++">C++</option>
                                    <option value="c">C</option>
                                  </select>
                                </div>
                                <Editor
                                  height="40vh"
                                  language={selectedLanguage}
                                  defaultValue="//Write some code here"
                                  value={selectedAnswer[currentQuestionIndex] || ""}
                                  theme="vs-dark"
                                  options={{
                                    fontSize: "16px",
                                  }}
                                  onChange={(newCode) => {
                                    const updatedCodeForQuestions = [...code];
                                    updatedCodeForQuestions[
                                      currentQuestionIndex
                                    ] = newCode;
                                    setSelectedAnswer(updatedCodeForQuestions);
                                  }}
                                />
                              </>
                            )}
                            {currentQuestion.question_type === 4 && (
                              <VideoRecorder
                                selectedAnswer={selectedAnswer}
                                setSelectedAnswer={setSelectedAnswer}
                                questionIndex={currentQuestionIndex}
                                data={QuestionList[currentQuestionIndex]}
                                permission={permission}
                                setPermission={setPermission}
                                UpdateLocalStorage={UpdateLocalStorage}
                                videoStopped={videoStopped}
                                setVideoStopped={setVideoStopped}
                              />
                            )}
                          </div>
                        </div>
                        <figcaption className="question-card-footer d-flex align-items-baseline ">
                          <div className="question-card-tools"></div>
                          <div className="question-card-navigation ml-auto">
                            {currentQuestionIndex > 0 &&
                              testPermissions.edit_answer && (
                                <button
                                  type="button"
                                  className="btn btn-primary m-2"
                                  id="customButton"
                                  onClick={handlePrevious}
                                  disabled={
                                    currentQuestion.question_type === 4 &&
                                    permission
                                      ? true
                                      : false
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    className="fontIcon "
                                  />{" "}
                                  Previous question
                                </button>
                              )}
                            {currentQuestionIndex ===
                            QuestionList.length - 1 ? (
                              <button
                                type="button"
                                ref={buttonRef}
                                className="btn btn-primary"
                                id="customButton"
                                onClick={handleSubmitTest}
                                disabled={
                                  currentQuestion.question_type === 4 &&
                                  permission
                                    ? true
                                    : false
                                }
                              >
                                {loading ? (
                                  <>
                                    <FontAwesomeIcon className="me-2" icon={faSpinner} size="lg" spin /> Submit
                                  </>
                                ) : (
                                  "Submit"
                                )}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-primary"
                                id="customButton"
                                onClick={handleNext}
                                disabled={
                                  currentQuestion.question_type === 4 &&
                                  permission
                                    ? true
                                    : false
                                }
                              >
                                {loadingForNext ? (
                                  <>
                                    <FontAwesomeIcon className="me-2" icon={faSpinner} size="lg" spin /> Next question <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="fontIcon"
                                />
                                  </>
                                ) : (
                                  <>
                                  Next question <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="fontIcon"
                                /></>
                                )}
                              </button>
                            )}
                          </div>
                        </figcaption>
                      </figure>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

    {/*Show Image Modal */}

    {isModalOpen && (
        <div className="modal image-modal" tabindex="-1" style={{ display: 'block' }} onClick={handleModalClose}>
          <div className="modal-dialog image-modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Question Image</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleModalClose}
                ></button>
              </div>
              <div className="modal-body">
                <img src={imageId} alt="Question" style={{ cursor: 'pointer', width: '100%' }} />
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default QuestionsPaper;