import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import QuestionsPaper from "./questionPapers/QuestionsPaper";

import { Provider } from "react-redux";
import store from "./questionPapers/Store";
import ThankYou from "./thankyou/ThankYou";
import { Helmet } from "react-helmet";
import OrganizationOnly from "./secureRoutes/OrganizationOnly";
import NotFound from "./pages/404/404";
import AudioVideoTest from "./pages/audio-video-test/AudioVideoTest";
import Home from "./pages/Home/Home";
import Payment from "./pages/payment/Payment";
import VerifyEmail from "./pages/Verify_email/VerifyEmail";
import FindApplicationForLogin from "./pages/FindApplicationForLogin/FindApplicationForLogin";
const root = ReactDOM.createRoot(document.getElementById("root"));

const MouseFlowTrackingCode = () => {
  window._mfq = window._mfq || [];
  (function () {
    var mf = document.createElement("script");
    mf.type = "text/javascript";
    mf.defer = true;
    mf.src =
      "//cdn.mouseflow.com/projects/424ad3ca-357f-4c5f-ae50-f6261d23b579.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();
};

root.render(
  <>
    <Provider store={store}>
      <MouseFlowTrackingCode />
      <BrowserRouter>
        <Routes>
          <Route element={<OrganizationOnly />}>
            <Route path="/login" element={<Login />}></Route>
          </Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/payment" element={<Payment />}></Route>
          <Route path="/verify-email" element={<VerifyEmail />}></Route>
          <Route path="/test" element={<QuestionsPaper />}></Route>
          <Route path="/thankyou" element={<ThankYou />}></Route>
          <Route path="/check-access" element={<AudioVideoTest />}></Route>

          <Route path="*" element={<NotFound />}></Route>
          <Route path="/find-application" element={<FindApplicationForLogin />}></Route>
        </Routes>
      </BrowserRouter>
    </Provider>
    <Helmet>
      <title>Online Skill Tests For Hiring Candidates - HRMWare</title>
      <meta name="description" content="HRMWare helps to hire top talents through online skill tests. Set up your profile and organization for creating test modules and inviting candidates to take tests."/>
    </Helmet>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
