import React, { useState, useEffect } from "react";
import "./FindApplication.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { axiosInstance } from "../../helper/axios-instance/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";


toast.configure({
  autoClose: 2000,
  pauseOnFocusLoss: false,
});

const FindApplicationForLogin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formData, setFormData] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [applicationData, setApplicationData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(30);

  const [checkedValue, setCheckedValue] = useState("");
  const navigate = useNavigate();

  // For Verifying Email

  const handleChange = (e) => {
    setFormData(e.target.value);
  };

  const onSubmit = async (data) => {
    try {
      const response = await axiosInstance.post(
        "candidate/find-application/otp",
        data
      );
      if (response.status === 200) {
        setIsOtpSent(true);
        toast.success("OTP Sent Successfully !")
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error("Error in submitting data", error);
    }
  };

  // For OTP verification
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return; // Allow only numbers
    // Update the OTP array with the new value
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // If the user clears the input, move focus to the previous input
    if (value === "" && index > 0) {
      const previousInput = document.getElementById(`otp-${index - 1}`);
      previousInput?.focus();
    }

    // Auto-focus the next input if a value is entered and it's not the last input
    if (value !== "" && index < otp.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    }
  };

  const handleOtpSubmit = async () => {
    const otpCode = otp.join("");
    const payload = {
      email_id: formData,
      otp: otpCode,
    };
    if (otpCode.length === 6) {
      try {
        const submitResponse = await axiosInstance.post(
          "candidate/find-application/otp/verify",
          payload
        );
        if (submitResponse.status === 200) {
          toast.success("OTP Verified Successfully!")
          console.log(submitResponse.data);
          setApplicationData(submitResponse.data);

          setIsOtpVerified(true);
        }
      } catch (error) {
        toast.error(error.response.data.message)
        console.error("Error in submitting OTP", error);
      }
    }
  };
  // Resend Otp
  const handleResendOtp = async () => {
    const payload = {
      email_id: formData
    }
    try {
      const response = await axiosInstance.post(
        "candidate/find-application/otp",
        payload
      );
      if (response.status === 200) {
        setOtp(["", "", "", "", "", ""])
        setIsOtpSent(true);
        toast.success("OTP Sent Successfully !")
      }
    } catch (error) {
      toast.error(error.response.data.message)
      console.error("Error in submitting data", error);
    }
    // Start the resend timer
    setIsDisabled(true);
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(intervalId)
          setIsDisabled(false)
          return 30;
        }
        return prevTimer - 1
      });
    }, 1000);
  }
  console.log("OTP", otp);

  useEffect(() => {
    if (timer !== 30) return;
  }, [timer]);



  const handleOtpPaste = (e) => {

    const pasteData = e.clipboardData.getData('text/plain');
    if (pasteData.length === 6 && /^[0-9]{6}$/.test(pasteData)) {
      const newOtp = pasteData.split("");
      setOtp(newOtp);
    }
  }

  // Test
  const handleStartTest = async () => {
    console.log("test");

    const payload = {
      candidate_id: checkedValue,
      email_id: formData,
    };

    try {
      await axiosInstance
        .post("candidate/start-test", payload)
        .then((response) => {
          console.log({ response });
          if (response.status === 200) {
            Cookies.set("candidate_token", response.data.data.token);
            Cookies.set("candidate_uuid", response.data.data.candidate_uuid);
            Cookies.set("app_id", response.data.data.application_id);

            const timeDuration = response.data.data.test_duration;
            const localtime = localStorage.getItem("duration");

            if (!localtime) {
              localStorage.setItem("duration", timeDuration);
            }

            navigate("/test");
          }
        });
    } catch (error) {
      console.error("Error in redirecting test", error);
    }
  };

  return (
    <div>

      <div className="main-bg-login">
        <div className="login-info">
          <div className="container-sm login-container" >
            <div className="row align-items-center login-row">
              <div className="col-md-6 left-section">
                <h3 className="fw-bold instruction">Some Key points to remember</h3>
                <ol>
                  <li className="list-item mt-2">Please ensure your camera and audio access are enabled.</li>
                  <li className="list-item mt-3">Before logging in, verify that your device has a stable <br />internet connection.</li>
                  <li className="list-item mt-3">During the test, do not open another tab or switch tabs.</li>
                  <li className="list-item mt-3">Please don't close your browser or refresh the page during the <br />test, as it will automatically submit your test</li>
                  <li className="list-item mt-3">Don't panic, if you by mistakely internet get disconnect or <br /> connection problem dont worry try to login again with your <br /> crendentials.</li>
                  <li className="list-item mt-3">Login again if accidently you logout ,the test will resume from <br /> where you end and data wont be lost.</li>
                  <li className="list-item mt-3">During the test, your image will be captured randomly.</li>
                  <li className="list-item mt-3">Be visible to the camera during the test.</li>
                </ol>
                <div className="image-sec">
                  <img src="images/imgpsh_fullsize_anim.png" alt="" className="collage-image" />

                </div>
              </div>


              <div className="col-md-6 right-section">
                {!isOtpSent ? (
                  <div className="logincard">
                    <div className="hrmlogo text-center">
                      <img
                        src="https://www.hrmware.com/images/logo-header.png"
                        alt="hrmwarelogo"
                      />
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="logingroup mb-4 mt-5">
                        <span className="input-group-text login__fieldicon bg-transparent">
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            className="badgeIcon"
                          />
                        </span>
                        <input
                          type="email"
                          {...register("email_id", {
                            required: "*Email is mandatory",
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: "Invalid Email Address",
                            },
                          })}
                          placeholder="Enter your email"
                          className="login-input"
                          onChange={handleChange}
                        />
                      </div>
                      {errors.email_id && (
                        <p className="validText">{errors.email_id.message}</p>
                      )}
                      <button
                        className="btn w-100 mt-3 custom-btn "
                        type="submit"
                      >
                        Send OTP
                      </button>
                    </form>
                    <div
                      className="position-relative check-access"
                      style={{ top: "140px" }}
                    >
                      <div className="btn btn-primary w-100 custom-btn-camera" onClick={() => { navigate("/check-access") }}>Check your <span className="subpart">Camera & Microphone Access</span> </div>
                    </div>
                  </div>
                ) : !isOtpVerified ? (
                  <div className="d-flex flex-column">
                    <h1 className="text-center mb-4">Verify Your OTP</h1>
                    <h5 className="mb-3 text-center">
                      Enter 6-digit verification code sent to your email address{" "}
                      <span style={{ color: "#3e50f7" }}>{formData}</span>
                    </h5>
                    <p
                      className="text-center fw-bold"
                      style={{ fontSize: "20px" }}
                    >
                      Enter Your code Here
                    </p>

                    <div
                      id="otp"
                      className="inputs d-flex flex-row justify-content-center mt-2 "
                    >
                      {console.log({ otp })}
                      {otp?.map((digit, index) => (
                        <input
                          key={index}
                          className="m-2 text-center form-control rounded otp-input"
                          type="text"
                          id={`otp-${index}`}
                          maxLength="1"
                          value={digit}
                          onChange={(e) => handleOtpChange(e, index)}
                          onFocus={(e) => e.target.select()}
                          onPaste={handleOtpPaste}
                        />
                      ))}
                    </div>

                    <button
                      className="btn btn-primary w-100 custom-btn mt-3"
                      onClick={handleOtpSubmit}
                    >
                      Submit
                    </button>
                    <button className="btn btn-light w-100 mt-3 resend-btn " onClick={handleResendOtp} disabled={isDisabled}>

                      {isDisabled ? `Resend in ${timer}s` : "Resend"}
                    </button>
                    {/* <a className="mt-3 text-center" onClick={() => { setIsOtpSent(false) }} style={{ cursor: "pointer", textDecoration: "none", color: "#3e50f7" }}>
                      Not You? Change Email Address
                    </a> */}
                    <p className="mt-3 text-center">Not You?<span className="part" style={{ color: "#3e50f7", cursor: "pointer" }} onClick={() => { setIsOtpSent(false) }}> Change Email Address</span></p>

                  </div>
                ) : (
                  <div
                    className="d-flex flex-column w-100"
                    style={{
                      padding: "30px !important",
                      overflowY: "auto",
                      maxHeight: "600px",
                    }}
                  >
                    <h3 className="text-center mb-4">Your Application(s)</h3>
                    {applicationData.data.map((item, index) => (
                      <div className="input-group mb-3 justify-content-center" key={index}>
                        <div className="input-group-text">
                          <input
                            className="form-check-input mt-0 "
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                            checked={checkedValue === item.application_id}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setCheckedValue(item.application_id);
                              } else {
                                setCheckedValue("");
                              }
                            }}
                          />
                          {console.log("checkedValue", checkedValue)}
                        </div>
                        <div className="input-box d-flex flex-column w-75">
                          <input
                            key={index}
                            type="text"
                            className="form-control application-link"
                            id={`id_${index}`}
                            aria-label="Text input with checkbox"
                            value={item.application_id}
                            readOnly
                          />
                        </div>
                      </div>
                    ))}
                    <button
                      className="btn btn-primary mt-4  w-100 test-btn"
                      onClick={handleStartTest}
                    >
                      Start Test
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >





  );
};
export default FindApplicationForLogin;