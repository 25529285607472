import "./ExitIntentModal.css";
import React, { useEffect, useRef } from "react";
import { IconXboxX } from '@tabler/icons-react';

const ExitIntentModal = ({ isOpen, onClose }) => {
  const modalRef = useRef(null);

  // Initialize the modal when the component mounts
  useEffect(() => {
    if (isOpen && modalRef.current) {
      const modalElement = modalRef.current;
      const modal = new window.bootstrap.Modal(modalElement, {
        backdrop: true,
        keyboard: true,
      });

      // Show the modal
      modal.show();

      // Cleanup: Hide the modal when the component unmounts
      return () => {
        modal.hide();
      };
    }
  }, [isOpen]);

  return (
    <div
      ref={modalRef}
      className="modal fade"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="exit-dialog modal-dialog modal-dialog-centered">

        <div className="modal-content">

          <div className="modal-body" >
            <div className="contact-form ">
              <div className="row m-0 main-row">
                <div className="col-12 col-xl-6 modal-left p-4">
                  <p className="text-center text-primary pb-2 mt-2 help-text fw-bold">NEED HELP ?</p>
                  <div className="row">
                    <div className="col-3 col-xl-6 card-col">
                      <div className="d-flex flex-column help-card justify-content-center">
                        <img
                          className="help-image"
                          src="Payroll.png"
                          alt="Payroll"
                        />
                        <p className="text-center card-details">Payroll Processing</p>
                      </div>
                    </div>
                    <div className="col-3 col-xl-6  card-col">
                      <div className="d-flex flex-column help-card justify-content-center">
                        <img
                          className="help-image"
                          src="Onboarding.png"
                          alt="Onboarding"
                        />
                        <p className="text-center card-details">Onboarding & Recruiting</p>
                      </div>
                    </div>
                    <div className="col-3 col-xl-6  card-col mt-sm-0 mt-xl-4">
                      <div className="d-flex flex-column help-card justify-content-center">
                        <img
                          className="help-image"
                          src="Reporting.png"
                          alt="Compliance"
                        />
                        <p className="text-center card-details">Compliance & Reporting</p>
                      </div>
                    </div>
                    <div className="col-3 col-xl-6  card-col mt-sm-0 mt-xl-4">
                      <div className="d-flex flex-column help-card justify-content-center">
                        <img
                          className="help-image"
                          src="Data.png"
                          alt="Data management"
                        />
                        <p className="text-center card-details">Data Management</p>
                      </div>
                    </div>
                    <div className="col-3 col-xl-6 card-col mt-4 d-none d-md-none d-xl-block ">
                      <div className="d-flex flex-column help-card justify-content-center">
                        <img
                          className="help-image"
                          src="Leave.png"
                          alt="Leave management"
                        />
                        <p className="text-center card-details">Leave Management</p>
                      </div>
                    </div>
                    <div className="col-3 col-xl-6  card-col mt-4 d-none d-md-none d-xl-block ">
                      <div className="d-flex flex-column help-card justify-content-center">
                        <img
                          className="help-image"
                          src="Attendance.png"
                          alt="Attendance Management"
                        />
                        <p className="text-center card-details">Attendance Management</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-6 modal-right p-4 ">
                  <IconXboxX stroke={2} onClick={onClose} className="close-btn" />
                  <h4 className=" right-content text-center text-dark pb-2 mt-2">
                    SUBMIT YOUR DETAILS AND OUR TEAM WILL CONTACT WITHIN 24 HOURS
                  </h4>
                  <iframe
                    className="form-frame"
                    width="100%"
                    height="490px" src="https://manage.vyrazu.com/forms/wtl/c9a526107342506c0604024c3684aa8d" allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExitIntentModal;