import { useEffect, useState } from "react";
import "./header.css";

const ExamHeader = ({ setTimeIsZero }) => {
  let state = localStorage.getItem("duration");

  const a = state?.split(":");
  const initialDuration = state ? +a[0] * 60 * 60 + +a[1] * 60 + +a[2] : 0;

  const [duration, setDuration] = useState(parseInt(initialDuration, 10));
  const [endTime] = useState(performance.now() + duration * 1000);

  const updateTimer = () => {
    const currentTime = performance.now();
    const elapsedSeconds = Math.floor((endTime - currentTime) / 1000);

    if (elapsedSeconds > 0) {
      setDuration(elapsedSeconds);
      requestAnimationFrame(updateTimer);
    } else {
      localStorage.removeItem("duration");
      setTimeIsZero(true);
    }
  };

  useEffect(() => {
    localStorage.setItem("duration", formatTime(duration));
    updateTimer();
  }, [duration, endTime]);

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
    const seconds = timeInSeconds - hours * 3600 - minutes * 60;

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <header className="header">
      <div className="container">
        <nav className="header-nav">
          <div className="logo-box">
            <img
              src="https://www.hrmware.com/images/logo-header.png"
              alt="hrmwarelogo"
              className="custom_headerLogo"
            />
          </div>
          <div className="timer-box">
            {/* <button type="button" className="btn btn-primary" id="startButton">
              Start
            </button> */}
            <p className="d-flex align-items-center my-0">
              Time Left:
              <span id="display">
                {!state ? "00:00:00" : formatTime(duration)}
              </span>
            </p>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default ExamHeader;