import { useEffect, useState } from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";

const Header = ({ scrollToPricing }) => {
  const navigate = useNavigate();
  return (
    <>
      <nav
        className="navbar navbar-expand-lg fixed-top "
        style={{
          padding: 0,
          justifyContent: "center",
          backgroundColor: "#fbfbfb",
        }}
      >
        <div
          className="container py-0 px-sm-0"
          style={{ position: "relative" }}
        >
          <a className="navbar-brand" href="/" style={{ margin: "0" }}>
            <img
              src="https://www.hrmware.com/images/logo-header.png"
              alt="logo"
              className="logo-img"
            />
          </a>

          <button
            className="navbar-toggler ml-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto pe-3">
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={() => scrollToPricing()}
                  style={{ cursor: "pointer" }}
                >
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <button className="btn mob-btn" style={{ cursor: "pointer" }}>
                  <a
                    className="btn-text text-white text-decoration-none"
                    onClick={() => navigate("/find-application")}
                  >
                    Candidate
                  </a>
                </button>
              </li>
              <li className="nav-item">
                <button className="btn mob-btn " style={{ margin: "0" }}>
                  <a
                    href="https://interviews-admin.hrmware.com/login"
                    className="btn-text text-white text-decoration-none"
                  >
                    Employer
                  </a>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
