import { IconBrandLinkedinFilled, IconBrandYoutubeFilled } from '@tabler/icons-react'
import React from 'react'
import "./Footer.css";

const Footer = ({ year }) => {

    return (
        <div>

            <section className="before-footer  action-box-print">
                <div className="container p-0">
                    <div className=" " style={{ padding: "2rem" }}>
                        <div className="row align-items-center">
                            <div className="col-lg-9 col-md-8 mb-3 mb-md-0">
                                <h2 className="free_trail-text mb-0 text-white">
                                    30 days free trial now! No credit card required
                                </h2>
                            </div>

                            <div className="col-lg-3 col-md-4 text-center">
                                <a
                                    className="try-btn btn btn-light "
                                    data-toggle="modal"
                                    data-target="#freeTrailModal"
                                    href="#"
                                >
                                    Try Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer-section footer bg-dark pt-lg-9 pt-7 pb-lg-8 pb-4">
                <div className="footer-container container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 ">
                            <a className="mb-5 d-block">
                                <img
                                    className="svg-injector logo-footer"
                                    src="https://hrm-dev.vyrazu.com/images/logo.png"
                                    alt="Logo"
                                />
                            </a>
                            <div className="pe-md-4">
                                <div className="position-relative">
                                    <img
                                        className="img-fluid"
                                        src="https://img.youtube.com/vi/ITNQ2iUJ_0c/maxresdefault.jpg"
                                        alt="About"
                                    />
                                    <a
                                        href="https://www.youtube.com/watch?v=ITNQ2iUJ_0c"
                                        className="btn-icon play-btn rounded-circle text-white d-inline-block btn-animation mb-2 position-all-center popup-youtube text-center"
                                    >
                                        <i className="fas fa-play text-white"></i>
                                    </a>
                                </div>

                                <p className="mt-4 text-white">
                                    Have a quick look into our Cloud HR Software. Best Solution to
                                    help you grow faster.
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-6 ">
                            <h4 className="col-header font-weight-normal text-white">
                                Calculators
                            </h4>
                            <ul className="list list-unstyled">
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/salary-calculator">Salary Calculator</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/epf-calculator">EPF Calculator Online</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/esi-calculator">ESI Calculator</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/time-card-calculator">Time Card Calculator</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/payslip-generator">Payslip Generator</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/expense-report/receipt-generator-online">
                                        <p className="mb-0" style={{ lineHeight: 1.3 }}>
                                            Expense Receipt Generator
                                        </p>
                                    </a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/hra-calculator">HRA Calculator</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/time-sheet-calculator">Time Sheet Calculator</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-6 ">
                            <h4 className="col-header  font-weight-normal text-white">
                                HR Services
                            </h4>
                            <ul className="list list-unstyled">
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/payroll-software-for-it-industry">IT Industry</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/payroll-software-for-accountants">Accountants</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/hr-software-for-healthcare">Healthcare</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/hr-software-for-bfsi-sector">BFSI Sector</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/payroll-software-for-retail">Retail</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/payroll-software-for-schools">Schools</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/real-estate-payroll-software">Real Estate</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/payroll-software-for-construction">Construction</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services/manufacturing-payroll-software">Manufacturing</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-6 ">
                            <h4 className="col-header font-weight-normal text-white">
                                Explore
                            </h4>
                            <ul className="list list-unstyled">
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/about-us">About Company</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/pricing">Pricing</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/services">Services</a>
                                </li>
                            </ul>
                            <h4 className="col-header font-weight-normal text-white mt-5">
                                Quick Links
                            </h4>
                            <ul className="list list-unstyled">
                                <li>
                                    <a className="text-light" target="_blank" href="https://www.hrmware.com/blog/">
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/privacy-policy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/refund-policy">Refund Policy</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/terms-and-conditions">Terms and Condition</a>
                                </li>
                                <li>
                                    <a className="text-light" href="https://www.hrmware.com/download/tracker">Download Tracker</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                            <h4 className="col-header font-weight-normal text-white">
                                Contact info
                            </h4>
                            <ul className="list list-unstyled">
                                <li className="d-flex mb-2 text-white">
                                    {" "}
                                    <span className="text-white pe-2">Address:</span>Webel IT
                                    Park, Tower 2, BN - 9, BN Block, Sector 5, Salt Lake City,
                                    Kolkata - 700091
                                </li>
                                <li className="d-flex mb-2">
                                    {" "}
                                    <span className="text-white pe-2">Email:</span>{" "}
                                    <a
                                        className="text-white"
                                        href="mailto:sales@vyrazu.com"
                                        target="_top"
                                    >
                                        sales@vyrazu.com
                                    </a>
                                </li>
                                <li className="d-flex mb-2 text-white">
                                    {" "}
                                    <span className="text-white pe-2">Phone:</span>+91 9038 457
                                    911
                                </li>
                                <li className="d-flex social-icons align-items-center">
                                    <span className="text-white pe-2">Share:</span>
                                    {/* <a href="https://www.facebook.com/vyrazuLabs/" target="_blank" className="btn btn-sm btn-icon social-btn btn-soft-primary text-white rounded-circle">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="https://twitter.com/vyrazulabs" target="_blank" className="btn btn-sm btn-icon social-btn  btn-soft-primary text-white rounded-circle">
                    <i className="fab fa-twitter"></i>
                  </a> */}
                                    <a
                                        href="https://in.linkedin.com/company/hrmware"
                                        target="_blank"
                                        className="btn btn-icon social-btn text-white "
                                    >
                                        {/* <i className="fab fa-linkedin"></i> */}
                                        <IconBrandLinkedinFilled
                                            stroke={5}
                                            style={{ height: "30px", width: "26px" }}
                                        />
                                    </a>
                                    <a
                                        href="https://www.youtube.com/@HRMWARE-sf7nv"
                                        target="_blank"
                                        className="btn btn-icon social-btn  text-white"
                                    >
                                        {/* <i className="fab fa-youtube"></i> */}
                                        <IconBrandYoutubeFilled
                                            stroke={5}
                                            style={{ height: "30px", width: "26px" }}
                                        />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12 text-center">
                            <p className="mb-0 text-white">
                                ©{year} Vyrazu Labs Pvt. Ltd. All rights reserved
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer