import "./login.css";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEnvelopeOpen,
  faIdBadge,
  faSpinner,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { authAxios } from "../helper/axios-instance/candidateOtp";
import { toast } from "react-toastify";
import { organisation } from "../helper/axios-instance/CanddiateQuestion";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
toast.configure({
  autoClose: 2000,
  pauseOnFocusLoss: false,
});
const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [timer, setTimer] = useState();
  const [minute, setMinute] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [isResendOTP, setIsResendOTP] = useState(false);
  const [sentStatus, setSentStatus] = useState("");
  const [verificationStatus, setVerificationStatus] = useState("");
  const [getOrganization, setGetOrganization] = useState();
  const [logoLoading, setLogoLoading] = useState(true);
  const [validCaptcha, setValidCaptcha] = useState(false);
  const REACT_APP_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [emailChanged, setEmailChanged] = useState('');
  const [addedEmail, setAddedEmail] = useState(emailChanged);
  const [loading, setLoading] = useState(false)
  const [loadingForLogin, setLoadingForLoading] = useState(false)

  const onSubmit = async (data) => {
    setLoadingForLoading(true)
    try {
      const requestBody = {
        email: data.email,
        candidate_id: data.candidate_id,
        otp: data.otp,
      };
      const response = await authAxios.post(`/otp/verify`, requestBody);
      const token = response.data.data.token;
      if (response.status === 200) {
        setLoadingForLoading(false)
        const timeDuration = response.data.data.test_duration;
        const localtime = localStorage.getItem("duration");

        if (!localtime) {
          localStorage.setItem("duration", timeDuration);
        }
        // setVerificationStatus(<p className='successText'>{response.data.message}</p>)
        toast.success(response.data.message);
        setSentStatus("");

        Cookies.set("candidate_token", token);
        Cookies.set("candidate_uuid", response.data.data.candidate_uuid);
        Cookies.set("app_id", data.candidate_id);

        navigate(`/test`, { state: timeDuration });
      }
    } catch (error) {
      setLoadingForLoading(false)
      if (error.response.status === 400 || error.response.status === 500) {
        toast.error(error.response.data.message);

        // setVerificationStatus(<p className='validText'>{error.response.data.message}</p>)
        setSentStatus("");
      }
    }
  };
  const [otpValue, setOtpValue] = useState("");
  const sendOtp = async (data) => {
    setLoading(true);
    setAddedEmail(data.email);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(async () => {
        try {
          const requestBody = {
            email: data.email,
            candidate_id: data.candidate_id,
          };
          const response = await authAxios.post("/otp", requestBody);
          if (response.status === 200) {
            setLoading(false);
            toast.success(response.data.message);
            setVerificationStatus("");
            setSubmit(true);
            setOtpValue("");
          }
        } catch (error) {
          setLoading(false);
          console.log(error);
          if (error.response.status === 400 || error.response.status === 500) {
            toast.error(error.response.data.message);
          }
          setVerificationStatus("");
        }
      })
      .catch(() => {
        toast.error("Turn on Camera and Microphone, and try again");
      });
  };
  useEffect(() => {
    let time = 60;
    setIsResendOTP(false);
    const timerInst = setInterval(() => {
      if (time > 0) {
        time -= 1;
        setTimer(time);
        setMinute(0);
      } else {
        clearInterval(timerInst);
      }
    }, 1000);
    return () => {
      clearInterval(timerInst);
    };
  }, [submit, isResendOTP]);
  const [searchParams] = useSearchParams();
  const OrganizationId = searchParams.get("key");
  Cookies.set("OrganizationId", OrganizationId);
  useEffect(() => {
    const getOrganization = async () => {
      setLogoLoading(true);
      organisation
        .get("/check?key=" + OrganizationId)
        .then((res) => {
          setLogoLoading(false);
          setGetOrganization(res.data.data.organisation_image);
        })
        .catch((error) => {
          setLogoLoading(false);
        });
    };

    getOrganization();
  }, []);
  // const verifyCaptcha = async (token) => {
  //   if (token === null) {
  //     setValidCaptcha(false);
  //   }
  //   await axios
  //     .post(process.env.REACT_APP_QUESTION_API + "auth/verify/recaptcha", {
  //       token: token,
  //     })
  //     .then((response) => {
  //       setValidCaptcha(true);
  //     })
  //     .catch((error) => {
  //       navigate("0");
  //     });
  // };

  const [videoId, setVideoId] = useState('');

  const openModal = () => {
    setVideoId('NlHp-f6yvs0');
    const modalElement = document.getElementById('videoModal');
    const modal = new window.bootstrap.Modal(modalElement);
    modal.show();
  };

  const handleEmailChange = (e) => {
    setEmailChanged(e.target.value);
  };

  return (
    <>
      <div className="main-bg">
        <div className="login-info">
          <div className="container-sm login-container" style={{ maxWidth: "1200px" }}>
            <div className="row align-items-center login-row">
              <div className="col-md-6 left-section">
                <h3 className="fw-bold instruction">Some Key points to remember</h3>
                <ol className="instruction-list">
                  <li className="list-item mt-2">Please ensure your camera and audio access are enabled.</li>
                  <li className="list-item mt-3">Before logging in, verify that your device has a stable <br />internet connection.</li>
                  <li className="list-item mt-3">During the test, do not open another tab or switch tabs.</li>
                  <li className="list-item mt-3">Please don't close your browser or refresh the page during the <br />test, as it will automatically submit your test</li>
                  <li className="list-item mt-3">Don't panic, if you by mistakely internet get disconnect or <br /> connection problem dont worry try to login again with your <br /> crendentials.</li>
                  <li className="list-item mt-3">Login again if accidently you logout ,the test will resume from <br /> where you end and data wont be lost.</li>
                  <li className="list-item mt-3">During the test, your image will be captured randomly.</li>
                  <li className="list-item mt-3">Be visible to the camera during the test.</li>
                </ol>
                <div className="image-sec">
                  <img src="images/imgpsh_fullsize_anim.png" alt="" className="collage-image" />

                </div>
              </div>

              <div className="col-md-6 right-section">
                <div className="hrmlogo text-center">
                  {logoLoading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      size={"2x"}
                      className="fa-spin"
                    />
                  ) : (
                    <img
                      src={
                        getOrganization
                          ? "data:image/jpeg;charset=utf-8;base64," +
                          getOrganization
                          : "https://www.hrmware.com/images/logo-header.png"
                      }
                      alt="hrmwarelogo"
                    />
                  )}
                </div>

                <div className="logincard">
                  <form className="loginitems">
                    <div className="logingroup mb-4 mt-3">
                      <span className="input-group-text login__fieldicon bg-transparent">
                        <FontAwesomeIcon icon={faIdBadge} className="badgeIcon" />
                      </span>
                      <input
                        type="text"
                        {...register("candidate_id", {
                          required: "*Id is mandatory",
                        })}
                        placeholder="Enter your application id"
                        className="logininput"
                        disabled={submit}
                      />
                    </div>
                    {errors.candidate_id?.type === "required" && (
                      <p className="validText">{errors.candidate_id.message}</p>
                    )}
                    <div className="logingroup mb-4 mt-3">
                      <span className="input-group-text login__fieldicon bg-transparent">
                        <FontAwesomeIcon
                          icon={faEnvelopeOpen}
                          className="badgeIcon"
                        />
                      </span>
                      <input
                        type="email"
                        {...register("email", {
                          required: "*Email is mandatory",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Invalid Email Address",
                          },
                        })}
                        placeholder="Enter your email"
                        className="logininput"
                        onChange={handleEmailChange}
                      />
                    </div>
                    {errors.email && (
                      <p className="validText">{errors.email.message}</p>
                    )}
                    {sentStatus && !submit && <p>{sentStatus}</p>}
                    {submit && (emailChanged === addedEmail) && (
                      <>
                        <div className="logingroup mb-2 mt-3">
                          <input
                            type="text"
                            {...register("otp")}
                            placeholder="Enter your OTP"
                            className="logininputOtp"
                            onChange={(e) => setOtpValue(e.target.value)}
                            value={otpValue}
                          />
                        </div>
                        {sentStatus && <p>{sentStatus}</p>}
                        {verificationStatus && <p>{verificationStatus}</p>}
                      </>
                    )}
                    {!submit ? (
                      <>
                        <button
                          type="submit"
                          className="btn btn-primary custom-btn mb-2 mt-2"
                          onClick={handleSubmit(sendOtp)}
                        >
                          {loading ? (
                            <>
                              <FontAwesomeIcon className="me-2" icon={faSpinner} size="lg" spin /> Send OTP
                            </>
                          ) : (
                            "Send OTP"
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary custom-btn mb-3 mt-3"
                          onClick={handleSubmit(onSubmit)}
                        >
                          {loadingForLogin ? (
                            <>
                              <FontAwesomeIcon className="me-2" icon={faSpinner} size="lg" spin /> Login
                            </>
                          ) : (
                            "Login"
                          )}
                        </button>

                        {(emailChanged === addedEmail) && <button
                          type="button"
                          className="btn btn-primary custom-btn mb-2 "
                          disabled={(timer || minute) === 0 ? false : true}
                          onClick={() => {
                            handleSubmit(sendOtp)();
                            setIsResendOTP(true);
                          }}
                        >
                          {loading ? (
                            <>
                              <FontAwesomeIcon className="me-2" icon={faSpinner} size="lg" spin /> Resend OTP
                            </>
                          ) : (
                            "Resend OTP"
                          )}
                          <span
                            className={`${timer === 0 ? "d-none" : "d-block "} `}
                          >
                            {minute < 10 ? `0${minute}` : minute}:
                            {timer < 10 ? `0${timer}` : timer}
                          </span>
                        </button>}
                      </>
                    )}
                  </form>
                  <div className="position-relative check-access" style={{ top: "140px" }}>
                    <div className="btn btn-primary w-100 custom-btn-camera" onClick={() => { navigate("/check-access") }}>Check your <span className="subpart">Camera & Microphone Access</span></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Demo Video Modal */}
        <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-video">
            <div className="modal-content">
              <button type="button" className="btn btn-sm modal-close-button" data-bs-dismiss="modal" aria-label="Close"><FontAwesomeIcon icon={faClose} size="xl" /></button>
              <div className="modal-body" style={{ height: "400px" }}>
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    width="100%"
                    height="400"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="YouTube Video"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
