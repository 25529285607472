import { IconBrandYoutube, IconXboxX } from "@tabler/icons-react";
import { IconChevronRight } from "@tabler/icons-react";
import "./Home.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../helper/axios-instance/AxiosInstance";
import Header from "../../header/Header";
import ExitIntentModal from "../../components/exit-intent/ExitIntentModal";
import Footer from "../../components/footer/Footer";

function Home() {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const currentYear = new Date();
  const year = currentYear.getFullYear();

  const scrollToPricing = (e) => {
    const pricingSection = document.getElementById("pricing");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const fetchPlansMonthly = () => {
    axiosInstance
      .get("plans/view-plans-monthly")
      .then((response) => {
        if (response.status === 200) {
          setPlans(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const fetchPlansYearly = () => {
    axiosInstance
      .get("plans/view-plans-yearly")
      .then((response) => {
        if (response.status === 200) {
          setPlans(response.data);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchPlansMonthly();
  }, []);

  const [loadingPlanId, setLoadingPlanId] = useState(null);

  const createSubscription = async (razorpay_plan_id, plan_type) => {
    setLoading(true);
    setLoadingPlanId(razorpay_plan_id);

    try {
      const response = await axiosInstance.post("payment/create/subscription", {
        plan_id: razorpay_plan_id,
        plan_type: plan_type,
      });

      setSubscriptionData(response.data.data);

      localStorage.setItem(
        "subscriptionData",
        JSON.stringify(response.data.data)
      );

      if (response.status === 200) {
        navigate("/payment", {
          state: {
            plans: plans,
            subscriptionData: response.data.data,
            subId: response.data.data.subscription_id,
            amount: response.data.data.amount,
          },
        });
      }

      setLoading(false);
    } catch (err) {
      console.error("error", err);
      setLoading(false);
    } finally {
      setLoadingPlanId(null); // Reset the loading state
    }
  };

  const [isMonthly, setIsMonthly] = useState(true);
  const [isInr, setIsInr] = useState(true);

  // Function to toggle between Monthly and Yearly
  const toggleMonthly = () => {
    setIsMonthly((prev) => !prev);
    fetchPlansMonthly();
  };

  const toggleYearly = () => {
    setIsMonthly((prev) => !prev);
    fetchPlansYearly();
  };

  const toggleUsd = () => {
    setIsInr((prev) => !prev);
  };

  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(progress); // Ref to store the current progress value
  const [hoveredTab, setHoveredTab] = useState(null);

  // Tab data (h3 and paragraph for each tab)
  const tabs = [
    {
      id: "tab2",
      title: "Video Question",
      description:
        "Our video question feature creates an opportunity for recruiters to assess not only the candidate’s technical skills but also their communication and problem-solving abilities in real-time.",
      content: "/video-question.jpg",
    },
    {
      id: "tab4",
      title: "Capture Focus Out",
      description:
        "Our Capture Focus Out feature provides an added layer of security by monitoring the candidate’s focus during the test, making sure they are not straying from the task at hand.",
      content: "/focus-out-history.jpg",
    },
    {
      id: "tab3",
      title: "Random Image Capture",

      description:
        "Our Random Image Capture feature takes periodic screenshots of the candidate’s surroundings during the test session, ensuring no cheating or unfair practices are involved.",
      content: "/capture-shots.jpg",
    },
    {
      id: "tab1",
      title: "New Question Paper Everytime",
      description:
        "Our innovative tool generates unique set of questions from a pre-defined question bank every time for each candidate. This ensures that no two candidates receive the same set of questions.",
      content: "/new-questions.png",
    },
  ];

  useEffect(() => {
    if (activeTab === null) return;

    // Reset progress when the active tab changes
    setProgress(0);
    progressRef.current = 0;
  }, [activeTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hoveredTab !== activeTab) {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            // Move to the next tab
            setActiveTab((prevTab) => (prevTab + 1) % tabs.length);
            return 0; // Reset progress
          }
          progressRef.current = prevProgress + 1;
          return progressRef.current;
        });
      }
    }, 30);

    return () => clearInterval(interval);
  }, [activeTab, tabs.length, hoveredTab]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to show the modal
  const showModal = () => {
    if (!isModalOpen && sessionStorage.getItem("modalShown") !== "true") {
      setIsModalOpen(true);
      sessionStorage.setItem("modalShown", "true"); // Mark modal as shown
    }
  };

  // Function to hide the modal
  const hideModal = () => {
    setIsModalOpen(false);
  };

  // Clear sessionStorage on page load to reset the modal state
  useEffect(() => {
    sessionStorage.removeItem("modalShown"); // Clear the modalShown key
  }, []);

  // Detect mouse movement toward the top of the page
  useEffect(() => {
    const handleMouseMove = (event) => {
      // Check if the mouse is near the top of the viewport (within 20px)
      if (event.clientY < 20) {
        showModal();
      }
    };

    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isModalOpen]);

  return (
    <div>
      <Header scrollToPricing={scrollToPricing} />
      <section className="hero1_section">
        <div className="container">
          <div id="hero1">
            <div className="content-section container p-0">
              <div className="row align-items-center first-row">
                <div className="text_section col-md-6">
                  <h1 className="company-badge mb-4 fs-5">
                    Online Skill Test - Remote Hiring
                  </h1>
                  <h2 className="mt-5 fs-1" style={{ fontWeight: 600 }}>
                    Hire Top Talents In Minutes
                  </h2>
                  <h3>Best Remote Interview Application</h3>
                  <p className="mt-5">
                    Finding the right talent for your organization is a
                    challenge.It becomes easy if you simplifying hiring process
                    and shortlist candidates in less time.Our online skill
                    tester is designed to help you do just that-seamlessly and
                    efficient.
                  </p>
                  <div className="buttons d-flex mt-5 gap-3">
                    <button className="btn btn-primary custom-btn1">
                      Create Tests For Free
                    </button>
                    <button className="btn btn-secondary1 custom-btn2">
                      <IconBrandYoutube stroke={1} /> Watch Demo
                    </button>
                  </div>
                </div>
                <div className="image_section col-md-6 d-flex justify-content-center align-items-center gap:5px ;">
                  <img
                    src="./skill-test-online.png"
                    alt="ilt"
                    height={"90%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section" id="aboutus">
        <div className="aboutus container">
          <div className="row d-flex second-row align-items-center">
            <div className="com-sm-12 col-md-6 second-row-col">
              <h2 className="mt-4" style={{ fontWeight: 600 }}>
                Shortlisting Can Be Tedious, Right? That’s Where Online Skill
                Tests Step In.
              </h2>
              <p className="mt-4 text-muted about-text">
                In today’s competitive world, finding the best talent isn’t just
                about scanning resumes; it’s about diving deeper into candidate
                skills. Resumes can provide an overview of a candidate’s
                experience, but they often fail to reveal the depth of their
                skills or their ability to excel in a specific role. This is
                where candidate skill tests become a crucial component of the
                recruitment process. To bridge this gap between shortlisting and
                hiring, HRMWARE Skill Tester helps:
              </p>
              <div className="row-feature mt-5"></div>
              <div className="container mt-5">
                <div className="row">
                  <div className="col-6 p-0">
                    <ul className="about-list mb-0">
                      <li>Streamline Shortlisting Process</li>
                      <li>Objective Decision Making</li>
                      <li>Support Remote Hiring</li>
                    </ul>
                  </div>

                  <div className="col-6 p-0">
                    <ul className="about-list">
                      <li>Proctoring in Online Classes</li>
                      <li>Improve Candidate Experience</li>
                      <li>Accurate Hiring Decisions</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="com-sm-12 col-md-6">
              <div className="image-wrapper ">
                <div className="images position-relative">
                  <img
                    src="./images/skill-test-features.png"
                    alt="Business Meeting"
                    className="img-fluid main-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container tab-container">
          <h2 className="text-center text-content-h2 text-content-header">
            Enhancing Test Experience with These Features
          </h2>

          <div className="row">
            {/* Tab Triggers */}
            <div className="col-lg-6 tab-buttons">
              {tabs.map((tab, index) => (
                <div
                  key={tab.id}
                  className={`tab-trigger ${
                    activeTab === index ? "active" : ""
                  }`}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    paddingLeft: "25px",
                  }}
                  onMouseEnter={() => setHoveredTab(index)} // Track hover for this tab
                  onMouseLeave={() => setHoveredTab(null)} // Reset hover when leaving
                  onClick={() => {
                    handleTabClick(index);
                  }}
                >
                  {/* Gray Border with Progress Indicator */}
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "4px",
                      height: "100%",
                      backgroundColor: "rgb(128 128 128 / 48%)", // Gray border
                    }}
                  >
                    {/* Progress Bar */}
                    <div
                      style={{
                        color: "#3E50F7",
                        position: "absolute",
                        top: 0, // Progress grows from top to bottom
                        left: 0,
                        width: "100%",
                        height: `${activeTab === index ? progress : 0}%`,
                        backgroundColor: "#3E50F7",
                        // transition: "height 0.3s ease",
                      }}
                    ></div>
                  </div>
                  <h2
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      marginBottom: "0.5rem",
                    }}
                  >
                    {tab.title}
                  </h2>
                  {activeTab === index && (
                    <p
                      className="mb-0"
                      style={{ fontSize: "18px", color: "#6c757d" }}
                    >
                      {tab.description}
                    </p>
                  )}
                </div>
              ))}
            </div>

            {/* Tab Content */}
            <div className="col-lg-6 tab-content-area" style={{ flex: 1 }}>
              {tabs.map((tab, index) => (
                <div
                  key={tab.id}
                  className={`tab-pane ${
                    activeTab === index ? "show active" : ""
                  }`}
                  style={{ display: activeTab === index ? "block" : "none" }}
                >
                  <img
                    src={tab.content}
                    alt=""
                    style={{ maxWidth: "100%", height: "400px" }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="services_section">
        <div className="services-container container">
          <h2 className="text-center text-content-header">
            Follow These Simple Steps for Quick Selection
          </h2>
        </div>

        <div className="container" style={{ marginTop: "90px" }}>
          <div className="row align-items-center sixth-row">
            <div className="col-md-6 sixth-row-col">
              <div className="wrapper-image-bg">
                <div className="browserWindowImg">
                  <img
                    src="./add-test.gif"
                    alt=""
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <h2 className="text-center" style={{ fontWeight: 600 }}>
                Unlimited Test Module Generation
              </h2>
              <h5 className="text-center mt-3">
                Add your skill-based questions individually and customize <br />
                thousands of test modules for skill tests..
              </h5>
            </div>
          </div>
          <div className="row align-items-center seventh-row">
            <div className="col-md-6 seventh-row-col">
              <h2 className="text-center" style={{ fontWeight: 600 }}>
                Create Candidates List
              </h2>
              <h5 className="text-center mt-3 mb-0">
                Add your candidates as much as you want <br /> and select tests
                for these candidates.
              </h5>
            </div>
            <div className="col-md-6">
              <div className="wrapper-image-bg">
                <div className="browserWindowImg">
                  <img
                    src="./candidate-invite.gif"
                    alt=""
                    style={{
                      maxWidth: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center eighth-row">
            <div className="col-md-6 eighth-row-col">
              <div>
                <div className="wrapper-image-bg">
                  <div className="browserWindowImg">
                    <img
                      src="./custom-candidate-test-page.gif"
                      alt=""
                      style={{
                        maxWidth: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="text-center" style={{ fontWeight: 600 }}>
                Automated Invitation
              </h2>
              <h5 className="text-center mt-3">
                An exam link will be automatically delivered to the candidate’s{" "}
                <br /> email ID by clicking on “Invite candidate”.
              </h5>
            </div>
            <div className="text-center pt-5 mt-5">
              <div className="btn btn-lg btn-primary custom-btn1">
                Try it now for FREE
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hero2_section">
        <div className="container ">
          <div className="row fourth-row">
            <div style={{ marginBottom: "30px" }}>
              <h2 className="text-center text-content-header">
                View Test Activities, Candidate Performance, And Results <br />{" "}
                With An Easy Yet Powerful Dashboard.
              </h2>
            </div>
            <div className="text-center">
              <img src="/Mockup.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <div id="pricing">
        <div className="pricing-text">
          <h2 className="text-center mb-0 text-content-header">Pricing</h2>
        </div>
        <div className="price-toggler-container">
          {/* Monthly/Yearly Toggle */}
          <div className="text-center" style={{ marginTop: "80px" }}>
            <div
              className="btn-group price-toggler"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                className={`btn btn-lg ${
                  isMonthly ? "btn-primary no-hover" : "btn-outline-primary"
                } select-monthly`}
                onClick={toggleMonthly}
                style={{
                  backgroundColor: isMonthly ? "#3E50F7" : "#ffffff",
                  borderColor: isMonthly ? "#3E50F7" : "#3E50F7",
                  color: isMonthly ? "#ffffff" : "#3E50F7",
                  cursor: "pointer",
                }}
              >
                Monthly
              </button>
              <button
                type="button"
                className={`btn btn-lg ${
                  !isMonthly ? "btn-primary no-hover" : "btn-outline-primary"
                } select-yearly`}
                onClick={toggleYearly}
                style={{
                  backgroundColor: !isMonthly ? "#3E50F7" : "#ffffff",
                  borderColor: !isMonthly ? "#3E50F7" : "#3E50F7",
                  color: !isMonthly ? "#ffffff" : "#3E50F7",
                  cursor: "pointer",
                }}
              >
                Yearly
              </button>
            </div>
          </div>

          {/* INR/USD Toggle */}

          <div className="currency-changer mt-4 text-center d-flex justify-content-center align-items-center">
            <div className="form-check form-switch custom-switch d-flex align-items-center">
              <label className="form-check-label h5 mb-0" htmlFor="usdSwitch">
                INR
              </label>
              <input
                type="checkbox"
                className="form-check-input mt-0 custom-switch-input"
                id="usdSwitch"
                checked={!isInr}
                onChange={toggleUsd}
                role="switch"
              />
              <label className="form-check-label h5 mb-0" htmlFor="usdSwitch">
                USD
              </label>
            </div>
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="pricing-container container">
          <div className="row">
            {plans.map((item, index) => (
              <div className="col-lg-3 col-md-6 pricing-col" key={index}>
                <div className="pricing-card d-flex flex-column justify-content-between">
                  <div className="content-wrap">
                    <h3 style={{ fontWeight: "500" }} className="mb-3">
                      {item.plan_name}
                    </h3>

                    {/* Display Price Based on Selected Options */}
                    {isMonthly
                      ? item.price_monthly_inr && (
                          <div
                            className="price"
                            style={{
                              marginLeft:
                                item.plan_name === "Basic" ? "0px" : undefined,
                            }}
                          >
                            <span className="currency fw-bold mb-3">
                              {/* {isInr && item.plan_name !== "Basic" ? "₹" : "$"} */}
                              {item.plan_name === "Basic"
                                ? ""
                                : isInr
                                ? "₹"
                                : "$" + " "}
                            </span>
                            <span
                              className={
                                item.plan_name === "Basic"
                                  ? "amount ms-0"
                                  : "amount"
                              }
                            >
                              {item.plan_name === "Basic"
                                ? "Free"
                                : isInr
                                ? item.price_monthly_inr
                                : item.price_monthly_usd}
                            </span>
                            <span className="time">
                              {item.plan_name === "Basic" ? "" : "/ month"}
                            </span>
                          </div>
                        )
                      : item.price_yearly_usd &&
                        item.plan_name !== "Enterprise" && (
                          <div
                            className="price"
                            style={{
                              marginLeft:
                                item.plan_name === "Basic" ? "0px" : undefined,
                            }}
                          >
                            <span className="currency fw-bold">
                              {item.plan_name === "Basic"
                                ? ""
                                : isInr
                                ? "₹"
                                : "$" + " "}
                            </span>
                            <span
                              className={
                                item.plan_name === "Basic"
                                  ? "amount ms-0"
                                  : "amount"
                              }
                            >
                              {item.plan_name === "Basic"
                                ? "Free"
                                : isInr
                                ? item.price_yearly_inr
                                : item.price_yearly_usd}
                            </span>
                            <span className="time">
                              {item.plan_name === "Basic" ? "" : "/ year"}
                            </span>
                          </div>
                        )}

                    <p className="mt-3">Features Included:</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.long_description,
                      }}
                    ></div>
                  </div>

                  {/* Subscription Button */}
                  <button
                    className="btn btn-primary card-btn mt-3 fw-bold fs-5"
                    onClick={() => {
                      if (
                        item.button_text !== "Contact Us" &&
                        item.button_text !== "Get Started"
                      ) {
                        createSubscription(
                          item.razorpay_plan_id,
                          isMonthly ? "monthly" : "yearly"
                        );
                      }
                    }}
                    disabled={loadingPlanId === item.razorpay_plan_id} // Disable button when loading
                  >
                    {loadingPlanId === item.razorpay_plan_id ? (
                      <div
                        className="spinner-border spinner-border-lg"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      item.button_text
                    )}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <section className="faq-9 faq section light-background " id="faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-5" data-aos="fade-up">
              <h2 className="faq_title mb-5 ">
                Have a question? Check out the FAQ
              </h2>
              <div
                className="faq-arrow d-none d-lg-block mt-3 ms-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <svg
                  className="faq-arrow"
                  width="200"
                  height="211"
                  viewBox="0 0 200 211"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "50px" }}
                >
                  <path
                    d="M198.804 194.488C189.279 189.596 179.529 185.52 169.407 182.07L169.384 182.049C169.227 181.994 169.07 181.939 168.912 181.884C166.669 181.139 165.906 184.546 167.669 185.615C174.053 189.473 182.761 191.837 189.146 195.695C156.603 195.912 119.781 196.591 91.266 179.049C62.5221 161.368 48.1094 130.695 56.934 98.891C84.5539 98.7247 112.556 84.0176 129.508 62.667C136.396 53.9724 146.193 35.1448 129.773 30.2717C114.292 25.6624 93.7109 41.8875 83.1971 51.3147C70.1109 63.039 59.63 78.433 54.2039 95.0087C52.1221 94.9842 50.0776 94.8683 48.0703 94.6608C30.1803 92.8027 11.2197 83.6338 5.44902 65.1074C-1.88449 41.5699 14.4994 19.0183 27.9202 1.56641C28.6411 0.625793 27.2862 -0.561638 26.5419 0.358501C13.4588 16.4098 -0.221091 34.5242 0.896608 56.5659C1.8218 74.6941 14.221 87.9401 30.4121 94.2058C37.7076 97.0203 45.3454 98.5003 53.0334 98.8449C47.8679 117.532 49.2961 137.487 60.7729 155.283C87.7615 197.081 139.616 201.147 184.786 201.155L174.332 206.827C172.119 208.033 174.345 211.287 176.537 210.105C182.06 207.125 187.582 204.122 193.084 201.144C193.346 201.147 195.161 199.887 195.423 199.868C197.08 198.548 193.084 201.144 195.528 199.81C196.688 199.192 197.846 198.552 199.006 197.935C200.397 197.167 200.007 195.087 198.804 194.488ZM60.8213 88.0427C67.6894 72.648 78.8538 59.1566 92.1207 49.0388C98.8475 43.9065 106.334 39.2953 114.188 36.1439C117.295 34.8947 120.798 33.6609 124.168 33.635C134.365 33.5511 136.354 42.9911 132.638 51.031C120.47 77.4222 86.8639 93.9837 58.0983 94.9666C58.8971 92.6666 59.783 90.3603 60.8213 88.0427Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="faq-container  ">
                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle1"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle1" className="faq-toggle-label">
                    <h6 style={{ color: " #3E50F7", margin: "0" }}>
                      What types of tests can I create?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>

                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      You can create technical, aptitude, personality, and video
                      tests tailored to your needs.
                    </p>
                  </div>
                </div>
                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle2"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle2" className="faq-toggle-label">
                    <h6 style={{ color: " #3E50F7", margin: "0" }}>
                      How does the dashboard work?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>

                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      The dashboard provides a comprehensive view of candidate
                      results and performance.
                    </p>
                  </div>
                </div>

                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle3"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle3" className="faq-toggle-label">
                    <h6 style={{ color: " #3E50F7", margin: "0" }}>
                      Can I customize question sets?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>
                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Absolutely! You can add your skill-based questions and
                      create custom test modules.
                    </p>
                  </div>
                </div>
                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle4"
                    className="faq-toggle-input"
                  />
                  <label htmlFor="faq-toggle4" className="faq-toggle-label">
                    <h6 style={{ color: " #3E50F7", margin: "0" }}>
                      What support is available for new users?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>

                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      We offer detailed guides, FAQs, and demo videos to ensure
                      you have a smooth experience.
                    </p>
                  </div>
                </div>

                <div className="faq-item ">
                  <input
                    type="checkbox"
                    id="faq-toggle5"
                    className="faq-toggle-input"
                  />

                  <label htmlFor="faq-toggle5" className="faq-toggle-label">
                    <h6 style={{ color: "#3E50F7", margin: "0" }}>
                      How can small businesses benefit from skill testing?
                    </h6>
                    <IconChevronRight stroke="2" className="faq-toggle-arrow" />
                  </label>
                  <div className="faq-content">
                    <p className="mt-3 mb-0">
                      Small businesses can save time, reduce hiring costs, and
                      ensure they select the most qualified candidates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="call-to-action-2"
        className="call-to-action-2 section dark-background "
      >
        <div className="container">
          <div
            className="row justify-content-center"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div className="col-xl-10">
              <div className="text-center">
                <div
                  className="h2 fw-bold"
                  style={{ color: "white", marginTop: "90px" }}
                >
                  Ready to Streamline Your Hiring Process?
                </div>
                <p
                  className="my-4"
                  style={{ color: "white", fontSize: "18px" }}
                >
                  Start leveraging our online skill test tool today and
                  experience the difference. It’s fast, user-friendly, and
                  loaded with advanced features to help you find the best
                  talents effortlessly.
                </p>
                <button
                  className="btn btn-lg btn-secondary ctabtn-2"
                  style={{ marginBottom: "90px" }}
                >
                  Get Started For Free
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="contact" className="contact section light-background">
        <div className="contact-section container">
          <h2 className="text-center" style={{ lineHeight: "40px" }}>
            Contact
          </h2>
        </div>
        <div
          className="contact-section-data-container container "
          style={{ marginTop: "85px" }}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div
            className="row  me-0 ps-3"
            style={{ justifyContent: "space-between" }}
          >
            <div className="col-12 col-md-6 modal-left p-4">
              <p className="text-center text-primary  pb-xl-4 pb-md-4  help-text fw-bold mb-0">
                NEED HELP ?
              </p>
              <div className="row">
                <div className="col-4 col-xl-6 card-col">
                  <div className="d-flex flex-column help-card justify-content-center">
                    <img
                      className="help-image"
                      src="Payroll.png"
                      alt="Payroll"
                    />
                    <p className="text-center card-details">
                      Payroll Processing
                    </p>
                  </div>
                </div>
                <div className="col-4 col-xl-6  card-col">
                  <div className="d-flex flex-column help-card justify-content-center">
                    <img
                      className="help-image"
                      src="Onboarding.png"
                      alt="Onboarding"
                    />
                    <p className="text-center card-details">
                      Onboarding & Recruiting
                    </p>
                  </div>
                </div>
                <div className="col-4 col-xl-6  card-col mt-xl-4  ">
                  <div className="d-flex flex-column help-card justify-content-center">
                    <img
                      className="help-image"
                      src="Reporting.png"
                      alt="Compliance"
                    />
                    <p className="text-center card-details">
                      Compliance & Reporting
                    </p>
                  </div>
                </div>
                <div className=" col-4 col-xl-6  card-col card-col1 mt-xl-4 mt-lg-4 mt-md-4">
                  <div className="d-flex flex-column help-card justify-content-center">
                    <img
                      className="help-image"
                      src="Data.png"
                      alt="Data management"
                    />
                    <p className="text-center card-details">Data Management</p>
                  </div>
                </div>
                <div className=" col-4 col-xl-6 card-col card-col1 mt-xl-4 mt-lg-4 mt-md-4  ">
                  <div className="d-flex flex-column help-card justify-content-center">
                    <img
                      className="help-image"
                      src="Leave.png"
                      alt="Leave management"
                    />
                    <p className="text-center card-details">Leave Management</p>
                  </div>
                </div>
                <div className="col-4 col-xl-6  card-col card-col1 mt-xl-4 mt-lg-4 mt-md-4 ">
                  <div className="d-flex flex-column help-card justify-content-center">
                    <img
                      className="help-image"
                      src="Attendance.png"
                      alt="Attendance Management"
                    />
                    <p className="text-center card-details">
                      Attendance Management
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-md-6 modal-right"
              style={{
                paddingLeft: "0px",
                paddingRight: "0px",
              }}
            >
              <IconXboxX stroke={2} className="close-btn" />
              <h4 className=" right-content text-center text-dark pb-2">
                SUBMIT YOUR DETAILS AND OUR TEAM WILL CONTACT WITHIN 24 HOURS
              </h4>
              <iframe
                className="form-frame"
                width="100%"
                height="490px"
                src="https://manage.vyrazu.com/forms/wtl/c9a526107342506c0604024c3684aa8d"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <Footer year={year} />
      <ExitIntentModal isOpen={isModalOpen} onClose={hideModal} />
    </div>
  );
}
export default Home;
