import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../helper/axios-instance/AxiosInstance";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import "./Payment.css";

const Payment = () => {
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState();
  const [amount, setAmount] = useState();
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    if (data && data.plans) {
      setPlans(data.plans);
    }
    if (data && data.subscriptionData) {
      setSubscriptionData(data.subscriptionData);
    }
    if (data && data.subId) {
      console.log("data",data);
      setSubscriptionId(data.subId);
    }
    if (data && data.amount) {
      setAmount(data.amount);
    }
  }, [data]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email_id: "",
    contact_number: "",
    password: "",
    repeat_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    let errors = {};
    if (!formData.first_name) errors.first_name = "First name is required";
    if (!formData.last_name) errors.last_name = "Last name is required";
    if (!formData.email_id) errors.email_id = "Email is required";
    if (!formData.contact_number)
      errors.contact_number = "Phone number is required";
    if (!formData.password) errors.password = "Password is required";
    if (formData.password !== formData.repeat_password)
      errors.repeat_password = "Passwords do not match";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = validate();
    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return; // Stop submission if there are validation errors
    }

    const newOptions = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      subscription_id: subscriptionId,
      name: "Vyrazu Labs Pvt. Ltd.",
      description: `HRMware-QA :${"SSS"}`,
      amount: amount,
      currency: "INR",
      prefill: {
        name: formData.first_name + " " + formData.last_name,
        email: formData.email_id,
        contact: formData.contact_number,
      },
      theme: {
        color: "#3E50F7",
      },
      handler: function (response) {
        alert(
          "Payment successful with reference id: " +
            response.razorpay_payment_id
        );
      },
      modal: {
        ondismiss: function () {
          console.log("Payment modal dismissed");
        },
      },
    };

    try {
      setIsSubmitting(true);

      const rzp1 = new window.Razorpay({
        ...newOptions, // Razorpay options
        handler: async function (response) {
          console.log("response", response);
          // This function will be called when payment is successful
          try {
            const registerResponse = await axiosInstance.post(
              "http://localhost:3033/auth/signup",
              formData
            );

            const emailData = {
              email: formData.email_id,
            };

            if (registerResponse.status === 200) {
              console.log("Signup successful");
              navigate("/verify-email", { state: { emailData } });
            } else {
              alert("Error: " + registerResponse.data.message);
            }
          } catch (error) {
            console.error("Error during registration:", error);
          } finally {
            setIsSubmitting(false);
          }
        },
        modal: {
          ondismiss: function () {
            // This function will be called if the user closes the Razorpay modal
            setIsSubmitting(false);
          },
        },
      });

      rzp1.open();
    } catch (error) {
      console.error("Error during Razorpay initialization:", error);
      alert(
        "An error occurred while initializing the payment. Please try again."
      );
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="main">
        <div className="container payment-container">
          <div className="row align-items-center justify-content-center back-bg">
            <div className="col-lg-6 left-col">
              <div className="left-section-reg">
                <div className="icon-leftsec">
                  <div
                    className="left-icon-box mt-1"
                    style={{ maxWidth: "50px" }}
                  >
                    <i
                      className="fas fa-shopping-cart"
                      style={{ color: "#3E50F7", fontSize: "25px" }}
                    ></i>
                  </div>
                  <div className="icon-cont">
                    <h3>Plan Information</h3>

                    <div className="product-description-description">
                      {plans.map((plan, index) =>
                        subscriptionData?.plan_id === plan.razorpay_plan_id ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: plan.long_description,
                            }}
                            key={index}
                          ></div>
                        ) : null
                      )}
                    </div>
                  </div>
                </div>
                <div className="icon-leftsec mt-4">
                  <div
                    className="left-icon-box mt-1"
                    style={{ maxWidth: "50px" }}
                  >
                    <i
                      className="fas fa-credit-card"
                      style={{ color: "#3E50F7", fontSize: "25px" }}
                    ></i>
                  </div>
                  <div className="icon-cont">
                    <h3>Payments</h3>
                    <p>
                      Start free trail and pay for the subscription after 30
                      days
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 right-col">
              <form className="row order-form" onSubmit={handleSubmit}>
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder="First Name"
                  />
                  {errors.first_name && (
                    <span className="text-danger">{errors.first_name}</span>
                  )}
                  <span className="focus-border"></span>
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="text"
                    className="form-control"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder="Last Name"
                  />
                  {errors.last_name && (
                    <span className="text-danger">{errors.last_name}</span>
                  )}
                  <span className="focus-border"></span>
                </div>
                <div className="form-group col-md-12 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="email_id"
                    value={formData.email_id}
                    onChange={handleChange}
                    placeholder="Enter email_id"
                  />
                  {errors.email_id && (
                    <span className="text-danger">{errors.email_id}</span>
                  )}
                  <span className="focus-border"></span>
                </div>

                <div className="form-group col-md-12 mt-3">
                  <input
                    type="text"
                    className="form-control"
                    name="contact_number"
                    value={formData.contact_number}
                    onChange={handleChange}
                    placeholder="Enter Phone Number"
                  />
                  {errors.contact_number && (
                    <span className="text-danger">{errors.contact_number}</span>
                  )}
                  <span className="focus-border"></span>
                </div>
                <div className="form-group col-md-12 mt-3">
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    placeholder="Password"
                  />
                  {errors.password && (
                    <span className="text-danger">{errors.password}</span>
                  )}
                  <span className="focus-border"></span>
                </div>
                <div className="form-group col-md-12 mt-3">
                  <input
                    type="password"
                    className="form-control"
                    name="repeat_password"
                    value={formData.repeat_password}
                    onChange={handleChange}
                    placeholder="Confirm Password"
                  />
                  {errors.repeat_password && (
                    <span className="text-danger">
                      {errors.repeat_password}
                    </span>
                  )}
                  <span className="focus-border"></span>
                </div>

                <div className="form-group checkbox-main col-md-12 mt-3">
                  <p className="mb-1">
                    By registering you confirm that you accept the{" "}
                    <a href="/terms-and-conditions">Terms of Service</a> and the{" "}
                    <a href="/privacy-policy">Privacy Policy</a>.
                  </p>
                </div>

                <div className="col-md-12">
                  <button
                    type="submit"
                    className="sign-up-button btn w-100 mt-3"
                    id="buyNowBtn"
                    data-style="zoom-in"
                    disabled={isSubmitting}
                  >
                    <span id="rzp-button-text" className="ladda-label">
                      {isSubmitting ? "Signing up..." : "Sign up for free"}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
