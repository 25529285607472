import React, { useState, useEffect } from "react";
import "./VerifyEmail.css";
import { useLocation, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../helper/axios-instance/AxiosInstance";

const VerifyEmail = () => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();

  const [isVerified, setIsVerified] = useState(false);

  // Ensure the email exists in state
  let email_id = state && state.emailData ? state.emailData : ""; // Default to empty string if email is undefined

  email_id = email_id.email;

  console.log("first", email_id);

  // OTP state to store the input values
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isDisabled, setIsDisabled] = useState(false); // For disabling the resend button
  const [timer, setTimer] = useState(30); // Timer for resend button

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/[^0-9]/.test(value)) return; // Allow only numbers

    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    // Automatically move to the next input field if current field is filled
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };



  const subscriptionData = localStorage.getItem("subscriptionData");
  const subscriptionDataObj = subscriptionData
    ? JSON.parse(subscriptionData)
    : null;

  // Handle OTP submit
  const handleOtpSubmit = async() => {
    const otpCode = otp.join("");
    if (otpCode.length === 6) {
      // Prepare the payload
      const payload = {
        email_id: email_id,
        otp: otpCode,
      };

      axiosInstance
        .post("auth/verify/email", payload)
        .then(async (response) => {
          if (response?.data?.success) {
            console.log("Email Verified Successfully");
            setIsVerified(true);

            console.log("response----......", response.data.userId);

            const subscriptionDataObjNew = {
              ...subscriptionDataObj,
              user_uuid: response.data.userId,
            };

            console.log("subscriptionDataObjNew", subscriptionDataObjNew);

            if (subscriptionDataObjNew.user_uuid!==null) {
              const subResponse = await axiosInstance.post(
                "http://localhost:3033/payment/add-subscription",
                subscriptionDataObjNew
              );
  
              if (subResponse.status === 200) {
                console.log("Subscription data added successfully");
                localStorage.removeItem("subscriptionData");
              }

              const orderResponse = await axiosInstance.post(
                "http://localhost:3033/payment/add-order",
                subscriptionDataObjNew
              );
  
              if (orderResponse.status === 200) {
                console.log("Subscription data added successfully");
                localStorage.removeItem("subscriptionData");
              }

            }
          }
        })
        .catch((error) => {
          console.error("Error verifying email:", error);
        });
    } else {
      alert("Please enter the 6-digit OTP");
    }
  };

  // Resend OTP logic
  const handleResendOtp = () => {
    setOtp(["", "", "", "", "", ""]);
    const payload = {
      email_id: email_id,
    };
    axiosInstance.post("auth/resend/email", payload).then((response) => {
      if (response?.data?.success) {
        console.log("OTP Resend Successfully");
      }
    });
    // Start the resend timer
    setIsDisabled(true);
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 1) {
          clearInterval(intervalId);
          setIsDisabled(false);
          return 30; // Reset the timer to 30 seconds
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    if (timer !== 30) return;
  }, [timer]);

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("Text").trim();
    if (pasteData.length === 6 && /^[0-9]{6}$/.test(pasteData)) {
      const newOtp = pasteData.split("");
      setOtp(newOtp);
    }
  };

  return (
    <>
      {!isVerified ? (
        <div
          className="container-sm"
          style={{ height: "80vh", marginTop: "220px" }}
        >
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="card otp-card">
                <div className="card-body p-5 text-center">
                  <h1 className="card-title mb-4">Verify Your Email</h1>
                  {/* Ensure email is rendered as a string */}
                  <h5 className="mb-3">
                    Enter 6-digit verification code sent to your email address{" "}
                    <span className="text-primary"> {String(email_id)}</span>
                  </h5>

                  <p
                    style={{ fontSize: "20px", fontWeight: 600 }}
                    className="mb-3"
                  >
                    Enter Code Here{" "}
                  </p>
                  <div
                    id="otp"
                    className="inputs d-flex flex-row justify-content-center mt-2"
                  >
                    {otp.map((digit, index) => (
                      <input
                        key={index}
                        className="m-2 text-center form-control rounded"
                        type="text"
                        id={`otp-${index}`}
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e, index)}
                        onFocus={(e) => e.target.select()} // Automatically select content when focusing
                        onPaste={handleOtpPaste}
                      />
                    ))}
                  </div>

                  <div
                    className="btn btn-primary w-100 my-3 custom-btns"
                    onClick={handleOtpSubmit}
                  >
                    Submit
                  </div>
                  <div
                    className="btn btn-light w-100 custom-btns"
                    onClick={handleResendOtp}
                    disabled={isDisabled}
                  >
                    {isDisabled ? `Resend in ${timer}s` : "Resend"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="after-verification">
          <div
            className="container-sm"
            style={{ height: "80vh", marginTop: "220px" }}
          >
            <div className="row justify-content-center">
              <div className="col-md-5 text-center">
                <div className="content-wrapper">
                  <img
                    src="https://cdn.pixabay.com/photo/2014/04/02/10/12/checkbox-303113_1280.png"
                    alt="Success"
                    width={150}
                    height={150}
                    className="mb-4"
                  />
                  <h2 className="text-success mb-4">
                    Email Verification Successful !
                  </h2>
                  <p className="mb-4" style={{ fontSize: "18px" }}>
                    You Have Successfully Verified Your Email.
                  </p>
                  <div
                    className="btn btn-primary login-redirect"
                    onClick={() =>
                      (window.location.href =
                        process.env.REACT_APP_REDIRECT_URL
                        // '/'
                      )
                    }
                  >
                    Go to Login{" "}
                    <span className="ms-3" style={{ fontSize: "20px" }}>
                      ➙
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyEmail;
